import React from 'react';

// Components
import {
  Layout,
  Container,
  ContentBlock,
  Testimonial,
  OrderProcess,
  NewsFeed,
} from '../components';

// Data
import data from '../data/pages/about.json';
import testimonials from '../data/testimonials.json';

const About = () => {
  const content = data.content;

  return (
    <Layout pageContext={data}>
      <Container addSpacers>
        {/* Statements */}
        <ContentBlock
          title={content.statement[0].title}
          content={content.statement[0].content}
          cta={content.statement[0].cta}
          buttonProps={{ styleType: 'flat', showArrow: true }}
        />
        <ContentBlock
          title={content.statement[1].title}
          content={content.statement[1].content}
          cta={content.statement[1].cta}
          buttonProps={{ styleType: 'flat', showArrow: true }}
        />

        {/* Testimonials */}
        {/* <div>
          <h2>{content.reviews.title}</h2>
          {testimonials.data.map((entry, i) => (
            <Testimonial key={i} text={entry.text} author={entry.author} />
          ))}
        </div> */}

        {/* Services */}
        {content.services.map((service, i) => (
          <ContentBlock
            key={i}
            title={service.title}
            content={[{ text: service.text }]}
            cta={service.cta}
            imagePath={service.imagePath}
            reverse={i % 2 > 0}
          />
        ))}

        <OrderProcess />
        <NewsFeed />
      </Container>
    </Layout>
  );
};

export default About;
